import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@masivapp/masivapp-sms-front",
  app: () => System.import("@masivapp/masivapp-sms-front"),
  activeWhen: ["/sms"],
});

registerApplication({
  name: "@masivapp/masivapp-whatsapp",
  app: () => System.import("@masivapp/masivapp-whatsapp"),
  activeWhen: ["/whatsapp"],
});

registerApplication({
  name: "@masivapp/masivapp-smsgateway-front",
  app: () => System.import("@masivapp/masivapp-smsgateway-front"),
  activeWhen: ["/sms-gateway"],
});

registerApplication({
  name: "@masivapp/masivapp-verifier-front",
  app: () => System.import("@masivapp/masivapp-verifier-front"),
  activeWhen: ["/verifier"],
});

registerApplication({
  name: "@masivapp/masivapp-automation-front",
  app: () => System.import("@masivapp/masivapp-automation-front"),
  activeWhen: ["/automation"],
});

registerApplication({
  name: "@masivapp/masivapp-hub-transaccional-front",
  app: () => System.import("@masivapp/masivapp-hub-transaccional-front"),
  activeWhen: ["/hub-transaccional", "/truauth/hub-transaccional"],
});

registerApplication({
  name: "@masivapp/masivapp-hub-transactional-front",
  app: () => System.import("@masivapp/masivapp-hub-transactional-front"),
  activeWhen: ["/hub-transactional"],
});

registerApplication({
  name: "@masivapp/masivapp-sherlock-front",
  app: () => System.import("@masivapp/masivapp-sherlock-front"),
  activeWhen: ["/sherlock", "/truscore"],
});

registerApplication({
  name: "@masivapp/masivapp-omnichannel-front",
  app: () => System.import("@masivapp/masivapp-omnichannel-front"),
  activeWhen: ["/omnichannel"],
});

registerApplication({
  name: "@masivapp/masivapp-dynamic-reports",
  app: () => System.import("@masivapp/masivapp-dynamic-reports"),
  activeWhen: ["/dynamic-reports"],
});

registerApplication({
  name: "@masivapp/masivapp-erp-front",
  app: () => System.import("@masivapp/masivapp-erp-front"),
  activeWhen: ["/erp"],
});

registerApplication({
  name: "@masivapp/masivapp-monitor-platform",
  app: () => System.import("@masivapp/masivapp-monitor-platform"),
  activeWhen: ["/monitor-platform"],
});

registerApplication({
  name: "@masivapp/masivapp-scout-front",
  app: () => System.import("@masivapp/masivapp-scout-front"),
  activeWhen: ["/scout"],
});

registerApplication({
  name: "@masivapp/masivapp-push-front",
  app: () => System.import("@masivapp/masivapp-push-front"),
  activeWhen: ["/push"],
});

registerApplication({
  name: "@masivapp/masivapp-voice-front",
  app: () => System.import("@masivapp/masivapp-voice-front"),
  activeWhen: ["/voice"],
});

registerApplication({
  name: "@masivapp/masivapp-platform-tools",
  app: () => System.import("@masivapp/masivapp-platform-tools"),
  activeWhen: ["/platform"],
});

registerApplication({
  name: "@masivapp/masivapp-email-front",
  app: () => System.import("@masivapp/masivapp-email-front"),
  activeWhen: (origin) => {
    let location = origin.pathname;
    let baseRoute = location.replace(/^\/|\/$/g, '');
    baseRoute = baseRoute.split('/');
    if (baseRoute[0] === 'email' && !location.includes('/email/preview/') && !location.includes('/email/preview?') && location != '/email/preview') return true;
    return false;
  },
});

// Aquí registrarías la SPA del email-preview
// Y en el dynamo también normal

registerApplication({
  name: "@masivapp/masivapp-email-preview-front",
  app: () => System.import("@masivapp/masivapp-email-preview-front"),
  activeWhen: ["/email/preview"],
});


registerApplication({
  name: "@masivapp/masivapp-shield-front",
  app: () => System.import("@masivapp/masivapp-shield-front"),
  activeWhen: ["/shield", "/truauth/armour"],
});

registerApplication({
  name: "@masivapp/masivapp-integrations-demos",
  app: () => System.import("@masivapp/masivapp-integrations-demos"),
  activeWhen: ["/integrations-demos"],
});

registerApplication({
  name: "@masivapp/masivapp-user-manager-front",
  app: () => System.import("@masivapp/masivapp-user-manager-front"),
  activeWhen: ["/user-manager"],
});

registerApplication({
  name: "@masivapp/masivapp-masivlinks-front",
  app: () => System.import("@masivapp/masivapp-masivlinks-front"),
  activeWhen: ["/links"],
});

registerApplication({
  name: "@masivapp/masivapp-landing-front",
  app: () => System.import("@masivapp/masivapp-landing-front"),
  activeWhen: ["/landing"],
});

registerApplication({
  name: "@masivapp/masivapp-integrations-bot2way",
  app: () => System.import("@masivapp/masivapp-integrations-bot2way"),
  activeWhen: ["/integrations-bot2way"],
});

registerApplication({
  name: "@masivapp/masivapp-login-front",
  app: () => System.import("@masivapp/masivapp-login-front"),
  activeWhen: ["/auth"],
});

registerApplication({
  name: "@masivapp/masivapp-sword-front",
  app: () => System.import("@masivapp/masivapp-sword-front"),
  activeWhen: ["/sword", "/truidv"],
});

registerApplication({
  name: "@masivapp/masivapp-permissions-front",
  app: () => System.import("@masivapp/masivapp-permissions-front"),
  activeWhen: ["/permissions"],
});

registerApplication({
  name: "@masivapp/masivapp-frequentcontacts-front",
  app: () => System.import("@masivapp/masivapp-frequentcontacts-front"),
  activeWhen: ["/frequent-contacts"],
});

registerApplication({
  name: "@masivapp/masivapp-home-front",
  app: () => System.import("@masivapp/masivapp-home-front"),
  activeWhen: ["/home"],
});

registerApplication({
  name: "@masivapp/masivapp-preloaded-files-front",
  app: () => System.import("@masivapp/masivapp-preloaded-files-front"),
  activeWhen: ["/preloaded-files"],
});

registerApplication({
  name: "@masivapp/masivapp-integrations-configurations",
  app: () => System.import("@masivapp/masivapp-integrations-configurations"),
  activeWhen: ["/integrations-configurations"],
});

registerApplication({
  name: "@masivapp/masivapp-auditlogs-front",
  app: () => System.import("@masivapp/masivapp-auditlogs-front"),
  activeWhen: ["/auditlogs"],
});

registerApplication({
  name: "@masivapp/masivapp-integrations-human-management",
  app: () => System.import("@masivapp/masivapp-integrations-human-management"),
  activeWhen: ["/integrations-human-management"],
});

registerApplication({
  name: "@masivapp/masivapp-accounts-front",
  app: () => System.import("@masivapp/masivapp-accounts-front"),
  activeWhen: ["/accounts"],
});

registerApplication({
  name: "@masivapp/masivapp-user-profile-front",
  app: () => System.import("@masivapp/masivapp-user-profile-front"),
  activeWhen: ["/profile"],
});

registerApplication({
  name: "@masivapp/masivapp-masiv-drive-container",
  app: () => System.import("@masivapp/masivapp-masiv-drive-container"),
  activeWhen: ["/masiv-drive"],
});

registerApplication({
  name: "@masivapp/masivapp-generator-extract",
  app: () => System.import("@masivapp/masivapp-generator-extract"),
  activeWhen: ["/generator-extract"],
});

registerApplication({
  name: "@masivapp/masivapp-live-traffic",
  app: () => System.import("@masivapp/masivapp-live-traffic"),
  activeWhen: ["/live-traffic"],
});

start();
